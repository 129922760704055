<script lang="ts" setup>
const consumerAuth = useConsumerAuthStore()
const joinBreakoutRoom = computed(() => consumerAuth.joinBreakoutRoom)
const teamName = computed(() => consumerAuth.dataPackage?.breakoutRoom?.breakout_room_name)
</script>

<template>
  <div @contextmenu.prevent>
    <UHeader class="bg-white shadow-md py-4 px-6 justify-between items-center">
      <template #logo>
        <span class="text-2xl font-semibold text-gray-900">SYNERGOS</span>
      </template>

      <template #center>
        <div v-if="joinBreakoutRoom" class="ml-auto mr-5 bg-red-400 rounded-md px-4">
          You are in your team room
        </div>
      </template>

      <template #right>
        <div v-if="teamName" class="text-lg">
          Your team is <span class="text-green-700 font-bold">{{ teamName }}</span>
        </div>
      </template>
    </UHeader>
  </div>
</template>